import { Accordion, Button, Flex, Image } from '@chakra-ui/react'
import { Text as UbiText } from '@ubirider/pick-component-library'
import { useTranslation } from 'react-i18next'
import { Faq } from './components/Faq'
import { HowToUseFaq } from './components/HowToUseFaq'
import { useRef } from 'react'
import { useLocation } from 'react-router-dom'

import faqUsageIconEN from '../../assets/custom_faq_en.svg'
import faqUsageIconES from '../../assets/custom_faq_es.svg'
import faqUsageIconPT from '../../assets/custom_faq_pt.svg'
import i18next from 'i18next'

export const Faqs = () => {
  const { t } = useTranslation()
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const location = useLocation()

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  if (location.state) {
    timeout(500).then(r => {
      const element = document.getElementById('learnMore')
      if (element) {
        const yOffset = windowSize.current[0] < 960 ? -70 : -120
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    })
  }

  return (
    <Flex
      w={'100%'}
      bg={{ sm: '', md: 'neutral.100', lg: 'neutral.100', xl: 'neutral.100' }}
      rounded='8px'
      padding={{ sm: '0px', md: '24px', lg: '24px', xl: '24px' }}
      direction='column'
    >
      <UbiText type='displayXS' weight='bold'>
        {t('faqs_fertagus.faqs')}
      </UbiText>
      <Accordion allowToggle defaultIndex={location.state ? [19] : []} flex='1'>
        <Faq number={'1'} title={t('faqs_fertagus.how_to_use_contactless_card_question')} description={t('faqs_fertagus.how_to_use_contactless_card_answer')} />
        <Faq number={'2'} title={t('faqs_fertagus.what_is_the_advantage_question')} description={t('faqs_fertagus.what_is_the_advantage_answer')} />
        <Faq number={'3'} title={t('faqs_fertagus.where_can_i_travel_question')} description={t('faqs_fertagus.where_can_i_travel_answer')} />
        <Faq number={'4'} title={t('faqs_fertagus.who_can_use_system_question')} description={t('faqs_fertagus.who_can_use_system_answer')} />
        <Faq number={'5'} title={t('faqs_fertagus.how_much_each_trip_costs_question')} description={t('faqs_fertagus.how_much_each_trip_costs_answer')} />
        <Faq number={'6'} title={t('faqs_fertagus.what_is_validity_trip_question')} description={t('faqs_fertagus.what_is_validity_trip_answer')} />
        <Faq number={'7'} title={t('faqs_fertagus.how_can_i_validate_question')} description={t('faqs_fertagus.how_can_i_validate_answer')} />
        <Faq number={'8'} title={t('faqs_fertagus.apple_pay_google_pay_available_question')} description={t('faqs_fertagus.apple_pay_google_pay_available_answer')} />
        <Faq number={'9'} title={t('faqs_fertagus.can_i_use_same_card_on_same_day_question')} description={t('faqs_fertagus.can_i_use_same_card_on_same_day_answer')} />
      </Accordion>
    </Flex>
  )
}
