import { Button, Collapse, Divider, Flex, Image, Link, Text, useDisclosure } from '@chakra-ui/react'
import { Button as UbiButton, Text as UbiText, Column, Center, FormItem, TextInput } from '@ubirider/pick-component-library'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AuthContext } from '../../AuthProvider'
import { googleLogin, facebookLogin, appleLogin } from '../../hooks/socialsLogin'
import { fetchSignInMethodsForEmail, getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import firebase from '../../Firebase'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Link as ReactLink } from 'react-router-dom'
import { getUserData } from '../../api/user'
import { VerifyAccountModal } from '../Modals/VerifyAccountModal/VerifyAccountModal'
import { LinkCredentialLoginModal } from '../Modals/LinkCredentialLoginModal'

import alertIcon from '../../assets/alert_red.svg'
import googleIcon from '../../assets/google.svg'
import facebookIcon from '../../assets/facebook.svg'
import appleIcon from '../../assets/apple.svg'

export const Login = () => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: null, password: null } })

  const navigate = useNavigate()
  const { t } = useTranslation()

  const email = watch('email')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenLink, onOpen: onOpenLink, onClose: onCloseLink } = useDisclosure()

  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [credential, setCredential] = useState(null)
  const [provider, setProvider] = useState(null)
  const [emailCred, setEmail] = useState(null)
  const [attemptedProvider, setAttemptedProvider] = useState(null)

  const onSubmit = async values => {
    const auth = getAuth()
    try {
      setLoading(true)
      AuthContext.user = await signInWithEmailAndPassword(firebase, values.email, values.password)
      console.log(AuthContext.user)
      const user = await getUserData()
      setLoading(false)
      console.log(user)
      navigate('/home')
      /*
            if (user.verify) {
                navigate("/home");
            }
            else {
                await signOut(firebase);
                onOpen();
            }*/
    } catch (error) {
      console.log(error)
      const methods = await fetchSignInMethodsForEmail(auth, values.email)
      setProvider(methods[0] === 'google.com' ? 'Google' : methods[0] === 'facebook.com' ? 'Facebook' : methods[0] === 'apple.com' ? 'Apple' : undefined)
      setAlert(true)
      setLoading(false)
    }
  }

  async function handleApple() {
    setLoading(true)
    try {
      await appleLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function handleFacebook() {
    setLoading(true)
    try {
      await facebookLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (typeof error === 'object') {
        //open modal here
        setCredential(error.credential)
        setProvider(error.provider === 'google.com' ? 'Google' : error.provider === 'apple.com' ? 'Apple' : error.provider === 'password' ? 'email and password' : null)
        setEmail(error.email)
        setAttemptedProvider('Facebook')
        onOpenLink()
      }
    }
  }

  async function handleGoogle() {
    setLoading(true)
    try {
      await googleLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  console.log(provider)

  return (
    <Flex direction='column' bg='white' rounded='8px' css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'} height={{ sm: 'calc(100vh - 70px)', lg: 'fit-content' }}>
      <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px', padding: '36px' }}>
        <UbiText type='displayXS' weight='bold'>
          {t('common.login')}
        </UbiText>
        <Column css={{ gap: '8px' }}>
          {provider && (
            <Collapse in={alert} animateOpacity>
              <Flex p='16px' gap='16px' color='white' bg='danger.100' rounded='8px' direction='row' align='start'>
                <Flex pt='5px'>
                  <Image w='44px' src={alertIcon} />
                </Flex>
                {provider && provider === undefined && (
                  <Flex align='flex-start' direction='column' gap='16px'>
                    <Text color='danger.600' fontWeight='400' fontSize='14px'>
                      {t('authentication.email_password_incorrect')}
                    </Text>
                    <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/reset' color='danger.600' fontWeight='600' fontSize='14px'>
                      {t('authentication.forgot_password')}
                    </Link>
                  </Flex>
                )}
                {
                  <Flex align='flex-start' direction='column' gap='16px'>
                    <Text color='danger.600' fontWeight='400' fontSize='14px'>
                      <Trans i18nKey='authentication.email_already_registered_provider'>
                        This email is already registered through a/an
                        <b>{{ provider }}</b> account. Please log in with it.
                      </Trans>
                    </Text>
                  </Flex>
                }
              </Flex>
            </Collapse>
          )}
          <Controller
            name='email'
            control={control}
            rules={{ required: t('authentication.required_field') }}
            render={({ field: { value, onChange } }) => (
              <FormItem label={t('authentication.your_email')} error={errors.email?.message}>
                <TextInput placeholder={t('authentication.email_address_placeholder')} name='email' id='email' value={value} onChange={onChange} />
              </FormItem>
            )}
          />
          <Controller
            name='password'
            control={control}
            rules={{ required: t('authentication.required_field') }}
            render={({ field: { value, onChange } }) => (
              <FormItem label={t('authentication.your_password')} error={errors.password?.message}>
                <TextInput placeholder={t('authentication.your_password')} name='email' id='email' password value={value} onChange={onChange} />
              </FormItem>
            )}
          />
        </Column>

        <Flex direction='column' gap='8px'>
          <UbiButton submit loading={loading} style='solid' type='secondary' label={t('common.login')} />

          <Flex justify='center'>
            <Link as={ReactLink} to='/reset' fontSize='16px' color='neutral.500' fontWeight='400'>
              {t('authentication.forgot_password')}
            </Link>
          </Flex>
        </Flex>

        <Flex gap='24px' justifyContent='center' alignItems='center'>
          <Divider border='1px solid #D0D5DD' />
          <Text color='neutral.400' fontSize='14px' fontWeight='400'>
            {t('authentication.or')}
          </Text>
          <Divider border='1px solid #D0D5DD' />
        </Flex>

        <Flex gap='8px' flex='1' justify='space-around'>
          <Button width='100%' onClick={handleGoogle} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
            <Image src={googleIcon} />
          </Button>
          <Button width='100%' onClick={handleFacebook} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
            <Image src={facebookIcon} />
          </Button>
          <Button width='100%' onClick={handleApple} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
            <Image src={appleIcon} />
          </Button>
        </Flex>

        <Center css={{ gap: '8px' }}>
          <Text color='neutral.500' fontWeight='400' fontSize='16px'>
            {t('authentication.no_account')}
          </Text>
          <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/register' fontSize='16px' color='neutral.600' fontWeight='700'>
            {t('authentication.create_account')}
          </Link>
        </Center>
      </Column>

      <VerifyAccountModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} email={email} />

      <LinkCredentialLoginModal
        isOpen={isOpenLink}
        onOpen={onOpenLink}
        onClose={onCloseLink}
        credential={credential}
        provider={provider}
        attemptedProvider={attemptedProvider}
        email={emailCred}
      />
    </Flex>
  )
}
