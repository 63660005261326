import { Box, Flex, FormControl, FormErrorMessage, Input, Text, Image, useToast, Link } from '@chakra-ui/react'
import { Button as UbiButton, Text as UbiText, Column, Row, Center, FormItem, TextInput } from '@ubirider/pick-component-library'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../AuthProvider'
import { sendPasswordResetEmail } from 'firebase/auth'
import firebase from '../../Firebase'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import alertIcon from '../../assets/alert.svg'
import closeIcon from '../../assets/cross_white_24.svg'

export const Reset = () => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { email: null } })

  const password = useRef({})
  password.current = watch('password', '')

  const email = useRef({})
  email.current = watch('email', '')

  const [loading, setLoading] = useState(false)
  const [emailSent, emailStatus] = useState(false)

  const toast = useToast()
  const toastIdRef = useRef()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const onSubmit = async values => {
    setLoading(true)
    try {
      AuthContext.user = await sendPasswordResetEmail(firebase, values.email)

      //console.log(AuthContext.user);
      emailStatus(true)
      //navigate('/');
    } catch (error) {
      console.log(error.message)
      toastIdRef.current = toast({
        position: 'top',
        duration: 5000,
        isClosable: true,
        render: () => (
          <Box alignItems='center' color='white' bg='danger.600' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
            <Flex justifyContent='space-between' gap='16px' padding='16px'>
              <Image src={alertIcon} />
              <Text fontSize='14px' fontWeight='600'>
                {t('authentication.email_not_associated')}
              </Text>
              <Image cursor='pointer' onClick={close} src={closeIcon} />
            </Flex>
          </Box>
        ),
      })
      setLoading(false)
    }
  }

  if (emailSent) {
    return (
      <Flex direction='column' bg='white' rounded='8px' padding='36px' justify='space-between' minH='412px' css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'}>
        <Column css={{ gap: '24px' }}>
          <UbiText type='displayXS' weight='bold'>
            {t('authentication.check_inbox')}
          </UbiText>
          <UbiText type='textMD'>
            {t('authentication.check_inbox_description', {
              email: email.current,
            })}
          </UbiText>
        </Column>
        <Center css={{ gap: '8px' }}>
          <Text color='neutral.500' fontWeight='400' fontSize='16px'>
            {t('authentication.back_to')}
          </Text>
          <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/' fontSize='16px' color='neutral.600' fontWeight='700'>
            {t('authentication.login_back_to')}
          </Link>
        </Center>
      </Flex>
    )
  }

  return (
    <Flex direction='column' bg='white' rounded='8px' css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'} height={{ sm: 'calc(100vh - 70px)', lg: 'fit-content' }}>
      <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px', padding: '36px' }}>
        <UbiText type='displayXS' weight='bold'>
          {t('authentication.forgot_password')}
        </UbiText>
        <UbiText type='textMD'>{t('authentication.reset_password_description')}</UbiText>
        <Controller
          name='email'
          control={control}
          rules={{ required: t('authentication.required_field') }}
          render={({ field: { value, onChange } }) => (
            <FormItem label={t('authentication.your_email')} error={errors.email?.message}>
              <TextInput placeholder={t('authentication.email_address_placeholder')} name='name' id='name' value={value} onChange={onChange} />
            </FormItem>
          )}
        />

        <UbiButton submit loading={loading} style='solid' type='secondary' label={t('authentication.reset_password')} />

        <Center css={{ gap: '8px' }}>
          <Text color='neutral.500' fontWeight='400' fontSize='16px'>
            {t('authentication.back_to')}
          </Text>
          <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/' fontSize='16px' color='neutral.600' fontWeight='700'>
            {t('authentication.login_back_to')}
          </Link>
        </Center>
      </Column>
    </Flex>
  )
}
