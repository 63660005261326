import { Modal, ModalOverlay, ModalContent, Flex, Image, Text } from '@chakra-ui/react'
import { Button as UbiButton, Column, Row, FormItem, TextInput, CountrySelect } from '@ubirider/pick-component-library'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { editBillingInfo } from '../../../api/billing'
import { AuthContext } from '../../../AuthProvider'

import closeButton from '../../../assets/cross.svg'
import arrowBackIcon from '../../../assets/arrow_left.svg'

export function EditBillingModal(props) {
  const { t } = useTranslation()
  const context = useContext(AuthContext)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      profile_name: props.info.profileName,
      name: props.info.name,
      country: props.info.country,
      tax_id: props.info.nif,
      address: props.info.address,
      postal_code: props.info.postalCode,
      city: props.info.city,
    },
  })

  const [loading, setLoading] = useState(false)

  const profileName = watch('profile_name')
  const billingName = watch('name')
  const country = watch('country')
  const taxID = watch('tax_id')

  useEffect(() => {
    console.log(props.info)

    reset({
      profile_name: props.info.profileName,
      name: props.info.name,
      country: props.info.country,
      tax_id: props.info.nif,
      address: props.info.address,
      postal_code: props.info.postalCode,
      city: props.info.city,
    })
    setLoading(null)
  }, [props.info, props.isOpen, props.onClose, reset])

  const onSubmit = async values => {
    await setLoading(true)
    try {
      const data = {
        profileName: values.profile_name,
        name: values.name,
        nif: values.tax_id,
        address: values.address ? values.address : '',
        postalCode: values.postal_code ? values.postal_code : '',
        city: values.city ? values.city : '',
        country: values.country,
      }
      console.log(data)

      editBillingInfo(data, props.id).then(() => {
        console.log('Entire Document has been updated successfully')
        setLoading(false)
        context.setRefresh(!context.refresh)
        props.onClose()
      })
    } catch (error) {
      setLoading(false)
      console.log(error.message)
    }
  }

  function validateTaxID(value) {
    if (country === 'PT' && value.length === 9) return true
    return !(country === 'PT' && value.length !== 9)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='column' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton
                onClick={props.onClose}
                style='ghost'
                type='neutralLight'
                label={t('authentication.back')}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: '0' }}
              />
            </Flex>
          )}
          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('billing_information.edit_billing_information')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={props.onClose}>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px' }}>
            <Column css={{ gap: '24px' }}>
              <Controller
                name='profile_name'
                control={control}
                render={({ field }) => (
                  <FormItem required label={t('billing_information.billing_profile_name')} error={errors.profile_name}>
                    <TextInput placeholder={t('billing_information.billing_profile_name_placeholder')} {...field} />
                  </FormItem>
                )}
              />

              <Column css={{ gap: '8px' }}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <FormItem required label={t('billing_information.billing_name')} error={errors.name}>
                      <TextInput placeholder={t('billing_information.billing_name_placeholder')} {...field} />
                    </FormItem>
                  )}
                />

                <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} gap='8px'>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field }) => (
                      <FormItem required label={t('billing_information.country')} error={errors.country}>
                        <CountrySelect {...field} />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='tax_id'
                    control={control}
                    rules={{
                      validate: value => validateTaxID(value) || t('authentication.invalid_tax_id'),
                    }}
                    render={({ field }) => (
                      <FormItem required label={t('common.tax_id')} error={errors.tax_id}>
                        <TextInput placeholder={t('common.tax_id')} {...field} />
                      </FormItem>
                    )}
                  />
                </Flex>

                <Flex display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }} direction='column' gap='8px'>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field }) => (
                      <FormItem required label={t('billing_information.country')} error={errors.country}>
                        <CountrySelect {...field} />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='tax_id'
                    control={control}
                    rules={{
                      validate: value => validateTaxID(value) || t('authentication.invalid_tax_id'),
                    }}
                    render={({ field }) => (
                      <FormItem required label={t('common.tax_id')} error={errors.tax_id}>
                        <TextInput placeholder={t('common.tax_id')} {...field} />
                      </FormItem>
                    )}
                  />
                </Flex>

                <Controller
                  name='address'
                  control={control}
                  render={({ field }) => (
                    <FormItem label={t('billing_information.address')} error={errors.name}>
                      <TextInput placeholder={t('billing_information.address_placeholder')} {...field} />
                    </FormItem>
                  )}
                />

                <Row css={{ gap: '8px' }}>
                  <Controller
                    name='postal_code'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormItem label={t('billing_information.postal_code')} error={errors.postal_code}>
                        <TextInput
                          placeholder={t('billing_information.postal_code')}
                          value={value}
                          onChange={e => {
                            if (!(e?.length > 8 && country === 'PT')) {
                              if (!isNaN(e?.slice(-1)) || e?.slice(-1) === '-') {
                                let inputValue = e
                                console.log(country)
                                if (value?.length > inputValue.length && value?.slice(-1) === '-') {
                                  inputValue = inputValue.slice(0, -1)
                                } else if (inputValue.length === 4 && !inputValue.includes('-')) {
                                  inputValue += '-'
                                }
                                onChange(inputValue)
                              }
                              if (e === null) {
                                onChange(e)
                              }
                            }
                          }}
                        />
                      </FormItem>
                    )}
                  />

                  <Controller
                    name='city'
                    control={control}
                    render={({ field }) => (
                      <FormItem label={t('billing_information.city')} error={errors.city}>
                        <TextInput placeholder={t('billing_information.city_placeholder')} {...field} />
                      </FormItem>
                    )}
                  />
                </Row>
              </Column>
            </Column>

            <UbiButton
              submit
              disabled={!(profileName && billingName && country && taxID)}
              loading={loading}
              style='solid'
              type='secondary'
              label={t('common.save')}
              css={{ alignSelf: 'stretch' }}
            />
          </Column>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
