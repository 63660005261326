import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { RichText } from '@ubirider/pick-component-library'
import successIcon from '../../../../assets/validator_success.png'
import insuccessIcon from '../../../../assets/validator_insuccess.png'
import contactlessIcon from '../../../../assets/contactless.svg'

export const Faq = props => {
  const getDescription = description => {
    const descriptionArray = description.split('%')

    if (descriptionArray.length === 1) return description

    return (
      <>
        {descriptionArray.map((item, index) => {
          if (index % 2 === 0) return <>{item}</>
          else {
            switch (item) {
              case 'SUCCESS':
                return <Image height='24px' width='24px' sx={{ display: 'inline', margin: '-8px 2px' }} src={successIcon} />
              case 'INSUCCESS':
                return <Image height='24px' width='24px' sx={{ display: 'inline', margin: '-8px 2px' }} src={insuccessIcon} />
              case 'CONTACTLESS':
                return <Image height='24px' width='24px' sx={{ display: 'inline', margin: '-8px 2px' }} src={contactlessIcon} />
              default:
                return <>{item}</>
            }
          }
        })}
      </>
    )
  }

  return (
    <AccordionItem marginBlock='16px' rounded='4px'>
      {({ isExpanded }) => (
        <Flex
          bg={isExpanded ? 'secondary.25' : 'neutral.25'}
          rounded='4px'
          border='1px'
          borderColor={isExpanded ? 'secondary.500' : 'neutral.300'}
          _hover={{
            border: '1px',
            borderColor: 'secondary.500',
          }}
          direction='column'
          css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'}
        >
          <AccordionButton
            paddingBlock='18px'
            _hover={{}}
            _expanded={{
              paddingBottom: '0px',
            }}
          >
            <Flex flex='1' justifyContent='space-between' align='center'>
              <Text fontSize='14px' fontWeight='700' align='left'>
                {props.number}. {props.title}
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb='16px'>
            <Divider color='neutral.300' />
            {props.children ?? null}
            <Flex flex='1' mt='8px'>
              <RichText>{props.description}</RichText>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}
