import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../AuthProvider'
import { LangSelector } from './components/LangSelector'
import { NotificationIcon } from './components/NotificationIcon'

export const TopBar = props => {
  const { t } = useTranslation()
  const Auth = useContext(AuthContext)
  const user = Auth.user

  return (
    <>
      <Flex display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} justifyContent='space-between'>
        <Text fontWeight='700' fontSize='30px'>
          {t('demo.my_mobility_account')}
        </Text>
        <Flex gap='18px'>
          <Flex align='center' gap='18px'>
            <Text>
              {t('home.welcome')} {props.name ? props.name.split(' ')[0] : user.email}
            </Text>
          </Flex>
          <Flex align='center' gap='8px'>
            {/*<NotificationIcon notification={false} /> */}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
