import React from 'react'
import { Image } from '@chakra-ui/react'
import { Button, Column, Text } from '@ubirider/pick-component-library'
import i18n from '../i18n'

import ErrorIcon from '../assets/error_bg.svg'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: !!error }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary: ', error, errorInfo)
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Column
          css={{
            gap: 24,
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
          }}
        >
          <Image src={ErrorIcon} w='238px' />
          <Text type='textLG' weight='bold' css={{ textAlign: 'center' }}>
            {i18n.t('home.unable_load_page_title')}
          </Text>
          <Column css={{ gap: 4 }}>
            <Text type='textMD' css={{ textAlign: 'center', color: '$neutral600' }}>
              {i18n.t('home.unable_load_page_description_1')}
            </Text>
            <Text type='textMD' css={{ textAlign: 'center', color: '$neutral600' }}>
              {i18n.t('home.unable_load_page_description_2', { operatorEmail: 'info@fertagus.pt' })}
            </Text>
          </Column>
          <Button type={'primary'} onClick={() => window.location.reload()} label={i18n.t('common.try_again')} css={{ marginTop: 16, minWidth: 200 }} />
        </Column>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
