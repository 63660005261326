import { Modal, ModalOverlay, ModalContent, Flex, ModalBody, Text, Box, Image, useToast } from '@chakra-ui/react'
import React, { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteBillingInfo } from '../../../api/billing'
import { AuthContext } from '../../../AuthProvider'

import closeIcon from '../../../assets/cross_white_24.svg'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'
import { IconWithinCircle } from '../../Common/IconWithinCircle'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import closeButton from '../../../assets/cross.svg'

export function DeleteProfileModal(props) {
  const [loading, setLoading] = useState(false)
  const context = useContext(AuthContext)

  const toast = useToast()
  const toastIdRef = useRef()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }
  const handleClickDelete = () => {
    try {
      setLoading(true)
      deleteBillingInfo(props.id).then(res => {
        console.log(res)
        console.log(props.id)
        context.setRefresh(!context.refresh)
        setLoading(false)
        //props.onOpenSM();
        props.onClose()
        toastIdRef.current = toast({
          position: 'top',
          duration: 5000,
          isClosable: true,
          render: () => (
            <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
              <Flex justifyContent='space-between' gap='16px' padding='16px'>
                <Image src={checkWhiteIcon} />
                <Text fontSize='14px' fontWeight='600'>
                  {t('billing_information.billing_information_deleted')}
                </Text>
                <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
              </Flex>
            </Box>
          ),
        })
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  const { t } = useTranslation()

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      size={{ sm: 'xs', md: 'xl', lg: 'xl', xl: 'xl' }}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='row' justifyContent='flex-end' alignItems='center'>
          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='48px' align='center'>
          <Flex direction='column' gap='16px' align='center'>
            <IconWithinCircle color='danger.600' icon={closeIcon} />
            <Text fontWeight='700' fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }} color='neutral.800' align='center'>
              {t('billing_information.delete_billing_information')}
            </Text>
            <Text
              fontWeight='600'
              fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
              color='neutral.600'
              align='center'
              width={{ md: '380px', lg: '380px', xl: '380px' }}
            >
              {t('billing_information.delete_billing_information_description')}
            </Text>
          </Flex>

          <Flex gap='16px' display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
            <UbiButton onClick={props.onClose} style='outline' type='secondary' label={t('common.cancel')} css={{ width: '160px' }} />
            <UbiButton onClick={handleClickDelete} loading={loading} style='solid' type='danger' label={t('common.delete')} css={{ width: '160px' }} />
          </Flex>
          <Flex gap='16px' alignSelf='stretch' direction='column' display={{ sm: 'flex', md: 'flex', lg: 'none', xl: 'none' }}>
            <UbiButton onClick={handleClickDelete} loading={loading} style='solid' type='danger' label={t('common.delete')} css={{ width: '100%' }} />
            <UbiButton onClick={props.onClose} style='outline' type='secondary' label={t('common.cancel')} css={{ width: '100%' }} />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
