import { Box, Flex, Button, Collapse, Image, Link, Text, Divider, useToast, useDisclosure } from '@chakra-ui/react'
import { Button as UbiButton, Text as UbiText, Column, Row, Center, FormItem, TextInput } from '@ubirider/pick-component-library'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../AuthProvider'
import firebase from '../../Firebase'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { googleLogin, facebookLogin, appleLogin } from '../../hooks/socialsLogin'
import { useForm, Controller } from 'react-hook-form'

import alertIcon from '../../assets/alert_red.svg'
import checkIcon from '../../assets/check_green.svg'
import closeIcon from '../../assets/cross_red.svg'
import googleIcon from '../../assets/google.svg'
import facebookIcon from '../../assets/facebook.svg'
import appleIcon from '../../assets/apple.svg'
import checkWhiteIcon from '../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../assets/cross_white_24.svg'
import { addUser } from '../../api/user'
import { LinkCredentialLoginModal } from '../Modals/LinkCredentialLoginModal'

export const Register = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const toast = useToast()
  const toastIdRef = useRef()
  const toastIdRef2 = useRef()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: null,
      email: null,
      password: null,
      confirm_password: null,
    },
  })

  const { isOpen: isOpenLink, onOpen: onOpenLink, onClose: onCloseLink } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [accountExists, setAccount] = useState(false)
  const [minCharacters, setMinCharacters] = useState(false)
  const [hasUppercase, setHasUppercase] = useState(false)
  const [hasLowercase, setHasLowercase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [verifyAccountScreen, toggleVerifyAccountScreen] = useState(false)

  const [credential, setCredential] = useState(null)
  const [provider, setProvider] = useState(null)
  const [emailCred, setEmail] = useState(null)
  const [attemptedProvider, setAttemptedProvider] = useState(null)

  const name = watch('name')
  const email = watch('email')
  const password = watch('password')
  const confirmPassword = watch('confirm_password')

  useEffect(() => {
    toggleVerifyAccountScreen(false)
    setLoading(false)
    reset({
      name: null,
      email: null,
      password: null,
      confirm_password: null,
    })
  }, [navigate, reset])

  useEffect(() => {
    checkPassword(password, confirmPassword)
  }, [password, confirmPassword])

  const checkPassword = (pwd, confirmPwd) => {
    if (pwd?.length >= 8) setMinCharacters(true)
    else setMinCharacters(false)

    if (/[A-Z]/.test(pwd)) setHasUppercase(true)
    else setHasUppercase(false)

    if (/[a-z]/.test(pwd) && pwd !== null) setHasLowercase(true)
    else setHasLowercase(false)

    if (/\d/.test(pwd)) setHasNumber(true)
    else setHasNumber(false)

    if (confirmPwd === pwd && pwd !== null) setPasswordsMatch(true)
    else setPasswordsMatch(false)
  }

  const onSubmit = async values => {
    setLoading(true)
    try {
      AuthContext.user = await createUserWithEmailAndPassword(firebase, values.email, values.password).then(function (result) {
        updateProfile(result.user, {
          displayName: values.name,
        }).then(
          async function () {
            console.log(result)
            await addUser(result.user.uid, name, navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase(), email, false, true)
            //toggleVerifyAccountScreen(true);
            navigate('/')
            setLoading(false)
            toastIdRef.current = toast({
              position: 'top',
              duration: 5000,
              isClosable: true,
              render: () => (
                <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
                  <Flex justifyContent='space-between' gap='16px' padding='16px'>
                    <Image src={checkWhiteIcon} />
                    <Text fontSize='14px' fontWeight='600'>
                      {t('authentication.account_created_toast')}
                    </Text>
                    <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
                  </Flex>
                </Box>
              ),
            })
          },
          function (error) {
            // An error happened.
          }
        )
      })
    } catch (error) {
      console.log(error.message)
      if (error.code === 'auth/email-already-in-use') {
        setAccount(true)
      }
      setLoading(false)
    }
  }

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  function resendEmail() {
    //missing request to send email
    toastIdRef2.current = toast({
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
          <Flex justifyContent='space-evenly' gap='16px' padding='16px'>
            <Image src={checkWhiteIcon} />
            <Text fontSize='14px' fontWeight='600'>
              {t('authentication.email_sent_toast')}
            </Text>
            <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    })
  }

  async function handleApple() {
    setLoading(true)
    try {
      await appleLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function handleFacebook() {
    setLoading(true)
    try {
      await facebookLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (typeof error === 'object') {
        //open modal here
        setCredential(error.credential)
        setProvider(error.provider === 'google.com' ? 'Google' : error.provider === 'apple.com' ? 'Apple' : error.provider === 'password' ? 'email and password' : null)
        setEmail(error.email)
        setAttemptedProvider('Facebook')
        onOpenLink()
      }
    }
  }

  async function handleGoogle() {
    setLoading(true)
    try {
      await googleLogin(navigate).then(() => {
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Flex direction='column' bg='white' rounded='8px' css={'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)'} flex='1'>
      {!verifyAccountScreen && (
        <Column as='form' onSubmit={handleSubmit(onSubmit)} css={{ gap: '24px', padding: '36px' }}>
          <UbiText type='displayXS' weight='bold'>
            {t('authentication.create_account')}
          </UbiText>
          <Column css={{ gap: '8px' }}>
            <Collapse in={accountExists} animateOpacity>
              <Flex p='16px' gap='16px' color='white' bg='danger.100' rounded='8px' direction='row' align='start'>
                <Flex pt='5px'>
                  <Image w='44px' src={alertIcon} />
                </Flex>

                <Flex align='flex-start' direction='column' gap='16px'>
                  <Text color='danger.600' fontSize='14'>
                    {t('authentication.email_already_registered')}
                  </Text>
                  <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/' color='danger.600' fontSize='14' fontWeight='600'>
                    {t('common.login')}
                  </Link>
                </Flex>
              </Flex>
            </Collapse>
            <Controller
              name='email'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem label={t('authentication.your_email')} error={errors.email}>
                  <TextInput placeholder={t('authentication.email_address_placeholder')} {...field} />
                </FormItem>
              )}
            />
          </Column>

          <Column css={{ gap: '8px' }}>
            <Controller
              name='name'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem label={t('authentication.your_name')} error={errors.name}>
                  <TextInput placeholder={t('authentication.your_name_placeholder')} {...field} />
                </FormItem>
              )}
            />
          </Column>
          <Column css={{ gap: '8px' }}>
            <Controller
              name='password'
              control={control}
              rules={{ required: t('authentication.required_field') }}
              render={({ field }) => (
                <FormItem label={t('authentication.your_password')} error={errors.password}>
                  <TextInput placeholder={t('authentication.create_password_placeholder')} password {...field} />
                </FormItem>
              )}
            />
            <Controller
              name='confirm_password'
              control={control}
              rules={{
                required: t('authentication.required_field'),
                validate: value => value === password || t('authentication.password_doesnt_match'),
              }}
              render={({ field }) => (
                <FormItem error={errors.confirm_password} label={''}>
                  <TextInput placeholder={t('authentication.create_password_placeholder')} password {...field} />
                </FormItem>
              )}
            />
          </Column>

          <Column css={{ gap: '8px' }}>
            <Text fontSize='14px' fontWeight='600' color='neutral.800'>
              {t('authentication.password_requirements')}
            </Text>
            <Column css={{ gap: '4px' }}>
              <Row css={{ gap: '8px' }}>
                <Image src={minCharacters ? checkIcon : closeIcon} />
                <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                  {t('authentication.at_least_8_characters')}
                </Text>
              </Row>
              <Row css={{ gap: '8px' }}>
                <Image src={hasUppercase ? checkIcon : closeIcon} />
                <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                  {t('authentication.one_uppercase')}
                </Text>
              </Row>
              <Row css={{ gap: '8px' }}>
                <Image src={hasLowercase ? checkIcon : closeIcon} />
                <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                  {t('authentication.one_lowercase')}
                </Text>
              </Row>
              <Row css={{ gap: '8px' }}>
                <Image src={hasNumber ? checkIcon : closeIcon} />
                <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                  {t('authentication.one_number')}
                </Text>
              </Row>
              <Row css={{ gap: '8px' }}>
                <Image src={passwordsMatch ? checkIcon : closeIcon} />
                <Text fontSize='14px' fontWeight='400' color='neutral.600' lineHeight='20px'>
                  {t('authentication.type_password_to_confirm')}
                </Text>
              </Row>
            </Column>
          </Column>

          <UbiButton
            submit
            loading={loading}
            disabled={!(minCharacters && hasUppercase && hasLowercase && hasNumber && passwordsMatch)}
            style='solid'
            type='secondary'
            label={t('authentication.create_account')}
          />

          <Center css={{ gap: '24px' }}>
            <Divider border='1px solid #D0D5DD' />
            <Text color='neutral.400' fontSize='14px' fontWeight='400'>
              {t('authentication.or')}
            </Text>
            <Divider border='1px solid #D0D5DD' />
          </Center>

          <Flex gap='8px' flex='1' justify='space-around'>
            <Button width='100%' onClick={handleGoogle} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
              <Image src={googleIcon} />
            </Button>
            <Button width='100%' onClick={handleFacebook} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
              <Image src={facebookIcon} />
            </Button>
            <Button width='100%' onClick={handleApple} padding='10px' border='1px solid #D0D5DD' boxShadow='0px 1px 2px rgba(16, 24, 40, 0.05)' bg='white'>
              <Image src={appleIcon} />
            </Button>
          </Flex>

          <Center css={{ gap: '8px' }}>
            <Text color='neutral.500' fontWeight='400' fontSize='16px'>
              {t('authentication.already_have_an_account')}
            </Text>
            <Link sx={{ textDecoration: 'underline' }} as={ReactLink} to='/' fontSize='16px' color='neutral.600' fontWeight='700'>
              {t('common.login')}
            </Link>
          </Center>
        </Column>
      )}
      {verifyAccountScreen && (
        <Column
          css={{
            padding: '36px',
            justifyContent: 'space-between',
            height: '412px',
          }}
        >
          <Flex gap='24px' direction='column'>
            <UbiText type='displayXS' weight='bold'>
              {t('authentication.check_your_inbox')}
            </UbiText>
            <UbiText type='textMD' css={{ color: '$neutral600' }}>
              {t('authentication.email_send_to_confirm_registration', {
                email: email,
              })}
            </UbiText>
          </Flex>
          <Flex gap='24px' direction='column' justify='flex-end'>
            <UbiButton
              onClick={() => {
                navigate('/')
              }}
              style='solid'
              type='secondary'
              label={t('authentication.back_to_login')}
              css={{ alignSelf: 'stretch' }}
            />
            <Center css={{ gap: '8px' }}>
              <Text color='neutral.500' fontWeight='400' fontSize='16px'>
                {t('authentication.resend')}
              </Text>
              <Link sx={{ textDecoration: 'underline' }} onClick={resendEmail} fontSize='16px' color='neutral.600' fontWeight='700'>
                {t('common.email')}
              </Link>
            </Center>
          </Flex>
        </Column>
      )}
      <LinkCredentialLoginModal
        isOpen={isOpenLink}
        onOpen={onOpenLink}
        onClose={onCloseLink}
        credential={credential}
        provider={provider}
        attemptedProvider={attemptedProvider}
        email={emailCred}
      />
    </Flex>
  )
}
