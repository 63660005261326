import axios from 'axios'
import { getBFFUrl, getToken } from './requests'

export const getTokenizedURL = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/tokenize`, {
    headers: {
      'x-access-token': token,
      'operator': 'Fertagus',
    },
    params: {
      redirect: 'window.location.href',
      operator: 'FERTAGUS',
    },
  })

  return res.data.data.gtwUrl
}

export const getCards = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/cards`, {
    params: {
      operator: 'FERTAGUS',
    },
    headers: {
      'x-access-token': token,
      'operator': 'Fertagus',
    },
  })

  return res.data.data.cards
}

export const deleteCard = async cardToken => {
  const token = await getToken()

  await axios.delete(`${getBFFUrl()}/cards?cardToken=${cardToken}`, {
    params: {
      operator: 'FERTAGUS',
    },
    headers: {
      'x-access-token': token,
      'operator': 'Fertagus',
    },
  })

  //console.log(res)
  return true
}

export const updateSettledCards = async cards => {
  const token = await getToken()

  const res = await axios.post(
    `${getBFFUrl()}/status`,
    { cards, operator: 'FERTAGUS' },
    {
      headers: {
        'x-access-token': token,
        'operator': 'Fertagus',
      },
    }
  )

  console.log(res.data)
  return res.data
}
