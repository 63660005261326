import { Modal, ModalOverlay, ModalContent, Flex, ModalBody, Text, Button } from '@chakra-ui/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithinCircle } from '../../Common/IconWithinCircle'

import checkIcon from '../../../assets/check_white.svg'

export function SuccessModal(props) {
  const { t } = useTranslation()

  const initialRef = useRef(null)
  const finalRef = useRef(null)

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      size={{ sm: 'xs', md: '2xl', lg: '2xl', xl: '2xl' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex direction='column' align='center' justifyContent='center' p={{ sm: '24px 0px', md: '24px 0px', lg: '48px', xl: '48px' }} gap='16px'>
            <IconWithinCircle color='green' icon={checkIcon} />
            <Text fontWeight='700' fontSize='24px' color='neutral.800'>
              {t('authentication.success')}
            </Text>
            <Text fontWeight='600' fontSize='20px' color='neutral.600' align='center'>
              {props.description}
            </Text>
            <Flex gap='16px'>
              <Button rounded='4px' border='1px' borderColor='success.700' bg='success.600' w='160px' onClick={props.onClose}>
                <Text color='neutral.25'>{t('customer_support.submit')}</Text>
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
