import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import i18next from 'i18next'
import { AuthProvider } from './AuthProvider'
import Landing from './pages/Landing/Landing'
import { Home } from './pages/Home'
import { Register } from './pages/Register/Register'
import { Login } from './pages/Login/Login'
import { Layout } from './Layout'
import { PrivateRoute } from './PrivateRoute'
import { Reset } from './pages/Reset/Reset'
import { NewPassword } from './pages/NewPassword/NewPassword'
import { Account } from './pages/Account'
import TravelHistoryPage from './pages/TravelHistoryPage/TravelHistoryPage'
import { Faqs } from './pages/Faqs'
import { BillingInfo } from './pages/BillingInfo'
import { Notifications } from './pages/Notifications'
import { CustomerSupport } from './pages/CustomerSupport'
import { UserDeleteInfo } from './pages/UserDeleteInfo/UserDeleteInfo'
import { setLanguage } from '@ubirider/pick-component-library'
import { DateTime } from 'luxon'

export const userTimezone = DateTime.local().zoneName

export const getLanguageFromBrowser = language => {
  if (language === 'PT') return navigator.language
  if (language === 'ES') return navigator.language
  return 'EN'
}

function App() {
  const language = localStorage.getItem('Language')
  const userLang = getLanguageFromBrowser(navigator.language)

  if (language) {
    i18next.changeLanguage(language)
    setLanguage(language.toLowerCase())
  } else {
    localStorage.setItem('Language', userLang)
    i18next.changeLanguage(userLang)
    setLanguage(userLang.toLowerCase())
  }

  return (
    <AuthProvider>
      <Router>
        <>
          <Flex gap='20px' direction={{ md: 'row', sm: 'column' }}>
            <Routes>
              <Route
                element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }
              >
                <Route path='/home' exact element={<Home />} />
                <Route path='/home/success' exact element={<Home />} />
                <Route path='/home/fail' exact element={<Home />} />
                <Route path='/travel-history' exact element={<TravelHistoryPage />} />
                <Route path='/faqs' exact element={<Faqs />} />
                <Route path='/account' exact element={<Account />} />
                <Route path='/billing-information' exact element={<BillingInfo />} />
                <Route path='/notifications' exact element={<Notifications />} />
                <Route path='/support' exact element={<CustomerSupport />} />
              </Route>

              <Route element={<Landing />}>
                <Route path='/' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/reset' element={<Reset />} />
                <Route path='/change-password' element={<NewPassword />} />
              </Route>

              <Route path='/delete-information' element={<UserDeleteInfo />} />

              <Route path='*' element={<Navigate to='/home' />} />
            </Routes>
          </Flex>
        </>
      </Router>
    </AuthProvider>
  )
}

export default App
