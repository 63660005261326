import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: navigator.language.substring(0, navigator.language.indexOf('-')).toUpperCase() === 'PT' ? 'PT' : 'EN',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    (err, t) => {
      if (err) return console.error(err)

      const updateMetaTags = () => {
        document.title = i18n.t('widget_website.contactless_portal_title')
        document.querySelector('meta[name="description"]').setAttribute('content', i18n.t('widget_website.contactless_portal_description_widget_vert'))
        document.querySelector('meta[property="og:title"]').setAttribute('content', i18n.t('widget_website.contactless_portal_title'))
        document.querySelector('meta[property="og:description"]').setAttribute('content', i18n.t('widget_website.contactless_portal_description_widget_vert'))
        document.querySelector('meta[property="twitter:title"]').setAttribute('content', i18n.t('widget_website.contactless_portal_title'))
        document.querySelector('meta[property="twitter:description"]').setAttribute('content', i18n.t('widget_website.contactless_portal_description_widget_vert'))
      }

      updateMetaTags()
      i18n.on('languageChanged', updateMetaTags)
    }
  )

export default i18n
