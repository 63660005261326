import { Modal, ModalOverlay, ModalContent, Flex, Image, List, Text, Box, useToast } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBillingInfo } from '../../../api/billing'
import { AuthContext } from '../../../AuthProvider'
import { changeInvoice } from '../../../api/invoice'
import NifComponent from './components/NifComponent/NifComponent'

import infoIcon from '../../../assets/information_purple.svg'
import closeButton from '../../../assets/cross.svg'
import grayPlus from '../../../assets/plus_gray.svg'
import checkWhiteIcon from '../../../assets/check_circle_white.svg'
import closeWhiteIcon from '../../../assets/cross_white_24.svg'
import arrowBackIcon from '../../../assets/arrow_left.svg'

export function SelectBillingModal({ onOpenBM, onOpen, onClose, isOpen, trips }) {
  const { t } = useTranslation()
  const context = useContext(AuthContext)
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const [billingInfo, setBilling] = useState([])
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [checkedNif, setCheckedNif] = useState({})
  const [checkedBilling, setCheckedBilling] = useState({})

  useEffect(() => {
    const getBilling = async () => {
      const billing = await getBillingInfo()

      setBilling(billing)
      setLoading(false)
      setCheckedNif({})
      setCheckedBilling({})
    }
    getBilling()
  }, [isOpen, onClose, context.refresh])

  const handleCheck = newNif => {
    setCheckedNif(newNif)
    setDisabled(false)
    billingInfo.forEach(billing => {
      if (billing.nif === newNif) setCheckedBilling(billing)
    })
  }

  const handleToast = () => {
    toastIdRef.current = toast({
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box alignItems='center' color='white' bg='#01AE70' border='1px solid #067F5B' boxShadow='box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)' borderRadius='4px'>
          <Flex justifyContent='space-between' gap='16px' padding='16px'>
            <Image src={checkWhiteIcon} />
            <Text fontSize='14px' fontWeight='600'>
              {t('travel_history.tax_id_changed')}
            </Text>
            <Image cursor='pointer' onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    })
  }

  const changeTaxID = async () => {
    try {
      setLoading(true)
      for (const trip of trips) {
        const res = await changeInvoice(trip.token, trip.date, checkedBilling)
        console.log(res)
        const link = document.createElement('a')
        link.href = res.invoice.link
        document.body.appendChild(link)
        link.click()
        link.download = res.invoice.name
        document.body.removeChild(link)
        setLoading(false)
        onClose()
        context.setRefresh(!context.refresh)
        handleToast()
      }
    } catch (error) {
      setLoading(false)
      console.log(error.message)
    }
  }

  const toast = useToast()
  const toastIdRef = useRef()

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: 'full', md: 'full', lg: 'xl', xl: 'xl' }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='column' flex='1' gap='24px' mt={{ sm: '67px', md: '67px', lg: '0px', xl: '0px' }}>
          {windowSize.current[0] < 960 && (
            <Flex alignItems='flex-start'>
              <UbiButton onClick={onClose} style='ghost' type='neutralLight' label={t('authentication.back')} leftIconProps={{ src: arrowBackIcon }} css={{ paddingLeft: '0' }} />
            </Flex>
          )}

          <Flex direction='row' justifyContent='space-between' alignItems='center'>
            <Text fontWeight='700' fontSize='24px' flex='1'>
              {t('travel_history.select_billing_information')}
            </Text>
            <Flex display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }} as='button' onClick={onClose} type='button'>
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Flex width='100%' pb='8px' p='16px' gap='16px' color='white' bg='secondary.100' rounded='4px' direction='row' align='start' border='1px solid #C7D7FE'>
            <Image w='20px' src={infoIcon} />
            <Flex align='flex-start' direction='column' gap='16px'>
              <Text color='secondary.600' fontWeight='400' fontSize='14px'>
                {t('travel_history.invoice_change_time_alert')}
              </Text>
            </Flex>
          </Flex>

          <Flex direction='column' gap='8px'>
            <List>
              {billingInfo.map((data, id) => (
                <NifComponent
                  key={id}
                  id={id}
                  info={data}
                  isChecked={checkedNif === data.nif}
                  onChange={() => {
                    handleCheck(data.nif)
                  }}
                />
              ))}
            </List>
            <UbiButton
              onClick={() => {
                onOpenBM()
                onClose()
              }}
              style='ghost'
              type='primary'
              leftIconProps={{ src: grayPlus }}
              label={t('billing_information.add_new_billing_information')}
            />
          </Flex>
          <UbiButton onClick={changeTaxID} loading={loading} disabled={disabled} style='solid' type='primary' label={t('travel_history.select')} css={{ alignSelf: 'stretch' }} />
        </Flex>
      </ModalContent>
    </Modal>
  )
}
