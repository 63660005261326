import { Flex, Image, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button as UbiButton } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithinCircle } from '../../Common/IconWithinCircle'

import deleteImage from '../../../assets/cross_white_24.svg'
import closeButton from '../../../assets/cross.svg'

export const DeleteCardModal = props => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={{ sm: 'xs', md: 'xl', lg: 'xl', xl: 'xl' }} closeOnOverlayClick={false} autoFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent padding={{ sm: '16px 24px', md: '16px 24px', lg: '36px', xl: '36px' }}>
        <Flex direction='row' justifyContent='flex-end' alignItems='center'>
          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='48px' align='center'>
          <Flex direction='column' gap='16px' align='center'>
            <IconWithinCircle color='danger.600' icon={deleteImage} />
            <Flex direction='column' gap='8px' align='center'>
              <Text fontWeight='700' fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '24px' }} color='neutral.800' align='center'>
                {t('home.delete_contactless_card')}
              </Text>
              <Text
                fontWeight='600'
                fontSize={{ sm: '14px', md: '14px', lg: '16px', xl: '16px' }}
                color='neutral.600'
                align='center'
                width={{ md: '380px', lg: '380px', xl: '380px' }}
              >
                {t('home.delete_contactless_card_description')}
              </Text>
            </Flex>
          </Flex>

          <Flex gap='16px' display={{ sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}>
            <UbiButton onClick={props.onClose} style='outline' type='secondary' label={t('common.cancel')} css={{ width: '98px' }} />
            <UbiButton onClick={props.onConfirm} loading={props.loading} style='solid' type='danger' label={t('common.delete')} css={{ width: '98px' }} />
          </Flex>
          <Flex gap='16px' alignSelf='stretch' direction='column' display={{ sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}>
            <UbiButton onClick={props.onConfirm} loading={props.loading} style='solid' type='danger' label={t('common.delete')} css={{ width: '100%' }} />
            <UbiButton onClick={props.onClose} style='outline' type='secondary' label={t('common.cancel')} css={{ width: '100%' }} />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
